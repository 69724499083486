 /* Scroll button */

 .myBtn {
    /* display: none; */
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }
  
  .myBtn:hover {
    background-color: #555;
  }