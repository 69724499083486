@media(min-width:576px){
 .rfq-stepper{
    width: 94%; 
    margin: 0 auto;
 }
 .inputfield{
    width: 25%;
 }
 .form-group.mb-1.inputfield input {border-radius: 10px;}
 .form-group.mb-1.inputfield select{ border-radius: 10px;}
 .form-group.mb-1 input {border-radius: 10px;}
 .labelStyle{font-weight: 300;}
 .nextPreviousform {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding-bottom: 3%;
}

.s_section {
   border: 1px solid #eee;
   padding-left: 1px;
   padding-right: 9px;
   padding-top: 3px;
   padding-bottom: 11px;
   margin-right: -7px
}

.innerclass{
   margin-right: 0;
}
}