.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: 0.5s ease;

  .modal-success {
    background-color: #fff;
    width: 30%;
    text-align: center;
    border-radius: 7px;
    color: rgba(32, 159, 132, 1);
    font-weight: bold;
    // margin-left: 20em;
    padding-top: 20px;

    button {
      margin: 0.4em 1em 1em auto;
      padding: 0.3em 2em;
      cursor: pointer;
      color: #fff;
      letter-spacing: 1.3px;
      border-radius: 4px;
      border: 1px solid#f2b74e;
      background-color: #f2b74e;
    }
  }
  .modal-error {
    background-color: #fff;
    width: 30%;
    text-align: center;
    border-radius: 7px;
    color: rgba(32, 159, 132, 1);
    font-weight: bold;
    // margin-left: 20em;
    padding-top: 20px;

    button {
      margin: 0.4em 1em 1em auto;
      padding: 0.3em 2em;
      cursor: pointer;
      color: #fff;
      letter-spacing: 1.3px;
      border-radius: 4px;
      border: 1px solid#f2b74e;
      background-color: #f2b74e;
    }
  }
}

// Form Succes Modal style

.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: 0.5s ease;

  .modal-success {
    background-color: #fff;
    width: 30%;
    text-align: center;
    border-radius: 7px;
    // color: rgba(32, 159, 132, 1);
    font-weight: bold;
    // margin-left: 20em;
    padding-top: 20px;

    h5 {
      color: #59903e;
      font-weight: bold;
      letter-spacing: normal;
      letter-spacing: 0.1em;
      font-size: 1rem;
    }

    button {
      margin: 0.4em 1em 1em auto;
      padding: 0.3em 2em;
      cursor: pointer;
      color: #fff;
      letter-spacing: 1.3px;
      border-radius: 4px;
      border: 1px solid#f2b74e;
      background-color: #f2b74e;
    }
  }
  .modal-error {
    background-color: #fff;
    width: 30%;
    text-align: center;
    border-radius: 7px;
    // color: rgba(32, 159, 132, 1);
    font-weight: bold;
    // margin-left: 20em;
    padding-top: 20px;

    h5 {
      color: #59903e;
      font-weight: bold;
      letter-spacing: normal;
      letter-spacing: 0.1em;
      font-size: 1rem;
    }

    button {
      margin: 0.4em 1em 1em auto;
      padding: 0.3em 2em;
      cursor: pointer;
      color: #fff;
      letter-spacing: 1.3px;
      border-radius: 4px;
      border: 1px solid#f2b74e;
      background-color: #f2b74e;
    }
  }
}
