

.Cards {
    /* Your card styles */
    position: absolute;
}

.triangle {
    /* Triangle styles */
    position: absolute;
    top: -12px;
    right: -108px;
    /* Adjust as needed */
    width: 0;
    rotate: 315deg;
    height: 0;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 80px solid yellow;
    /* Red color; adjust as needed */
}

.approved-text {
    /* Text inside the triangle */
    position: absolute;
    top: 52px;

    transform: translate(-50%, -50%);
    /* White color; adjust as needed */
    font-size: 15px;
    /* Adjust font size */
}

.paddingStyle{
    padding-left: 12px;
    padding-right: 12px;
}

@media (min-width:992px) {
   .widthStyle{
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
   }
}

@media (min-width:576px ) and (max-width:991px) {
    .widthStyle{
     flex: 0 0 50%;
     max-width: 50%;
     position: relative;
     width: 100%;
    }
 }

 @media (max-width:576px) {
    .widthStyle{
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
       }
 }



 
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}
.backButton{
  font-style: normal;
   font-weight: 500;
   line-height: 26px;
   font-size: 1.1rem;
   display: flex;
   align-items: center;
   color: #475e79;
   position: fixed;
   z-index: 2;
   right: 20px;
 }
.gallery {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2,1fr);
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  margin-top: 2.2rem;
  width: 95%;
}

.parent{
  position: fixed;
  z-index: 1;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vh;
  overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: white;
}
.gallery > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.gallery .tall {
  grid-row: span 2;
}

.no-scroll{
  overflow: hidden;
}

.crossIcon{
  top: 105px;
  right: 155px;
  width: 40px;
  z-index: 3000;
}


@media(max-width:576px){
  .backButton{
    top: 112px;
    left: 16px;
  }
  .gallery{
    width: 95%;
    grid-auto-rows: 140px;
  }
  .crossIcon{
    right: 45px;
    top: 100px;
  }
}

@media (min-width:768px) {
  .backButton{
    top: 180px;
  }
}

@media (min-width:1024px) {
  .backButton{
    top: 122px;
  }
}