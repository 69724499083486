@import './css1/icons.min.css';

.accoutn-input .input-group-append {
  border: 1px solid #ced4da;
  background-color: #f7f7f7;
}

.cLxgFs.sc-bxivhb {
  padding-top: 8px;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '>' !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.image-gallery {
  width: 100%;
}

.usable {
  margin-top: 18px !important;
}

.sidebar-link {
  color: #fff !important;
}

.sidebar-link:hover {
  color: #fff !important;
}

.sidebar-link i {
  color: #fff !important;
}

.sidebar-link i:hover {
  color: #fff !important;
}

.sb-avatar__text {
  width: 43px !important;
  height: 43px !important;
}

.form-control.form-control-md {
  border-radius: 0px;
}

.border.btn.btn-block.dropdown-toggle {
  border-radius: 0px;
  background-color: white !important;
}

table th,
td {
  border: 1px solid #dee2e6 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000;
}

.accord-heading {
  line-height: 1px !important;
}

.sidebar-link-active {
  color: #fff !important;
}

.sidebar-link-active .MuiListItem-button {
  border: 1px solid #000 !important;
  border-radius: 50px !important;
  background-color: #f2b74e !important;
}

.sidebar-link-active:hover {
  color: #fff !important;
}

.sidebar-link-active i {
  color: #fff !important;
}

.sidebar-link-active i:hover {
  color: #fff !important;
}

.roundabout {
  background: white !important;
}

.side-dropdown-menu {
  color: #fff !important;
}

.side-dropdown-menu i {
  color: #fff !important;
}

.side-dropdown-menu i:hover {
  color: #fff !important;
}

.side-dropdown-menu:hover {
  background-color: #f2b74e !important;
  border-radius: 50px !important;
}

.side-dropdown-menu-active {
  background-color: #f2b74e !important;
  border-radius: 50px !important;
}

.side-dropdown-menu-active i {
  color: #fff !important;
}

.side-dropdown-menu-active i:hover {
  color: #fff !important;
}

.MuiListItem-button:hover {
  border: 1px solid #000 !important;
  border-radius: 50px !important;
  background-color: #f2b74e !important;
}

.sidebar-active-link {
  color: #a54949;
}

.makeStyles-root-1 {
  margin-left: auto !important;
  margin-right: auto !important;
}

.drawer-background {
  background-color: #000;
}

.makeStyles-heading-2 {
  flex-basis: 90% !important;
}

.accord-heading {
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #475e79;
  padding: 0px !important;
  flex-basis: 90% !important;
}

.listofwarehouse {
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: #475e79;
}

.cursorPointer {
  cursor: pointer;
}

.content-admin {
  background-color: #f2f4f8;
  width: calc(100% - 280px) !important;
  margin-left: auto;
  min-height: 100vh;
}

.spinner-border {
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin-left: 0.5rem;
}

.errorMessage {
  color: #ff5733;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.custom-loader {
  text-align: center;
  line-height: 2rem;
  vertical-align: middle !important;
  margin-top: 10%;
}

.custom-loader .spinner-border {
  height: 3rem !important;
  width: 3rem !important;
  border-top-color: #f2b74e;
  border-bottom-color: #f2b74e;
  border-left-color: #f2b74e;
}

.customer-active {
  color: #000 !important;
  border-bottom: 5px solid #f2b74e !important;
}

.vendorActive {
  color: #f2b74e !important;
  // border-bottom: 2px solid #f2b74e !important;
  padding-bottom: 0px !important;
}

.btn:focus {
  box-shadow: none !important;
}

.MuiAccordion-root {
  margin-bottom: 5px !important;
}

.makeStyles-heading-2 {
  padding: 5px !important;
}

.makeStyles-heading-5 {
  flex-basis: 73.33% !important;
}

.form-control:disabled {
  background-color: #f7f7f7 !important;
}

.errorMsg {
  color: #ef0e0e;
  font-size: 12px;
}

.customTable td {
  padding: 0.25rem !important;
}

.listTable tbody tr {
  border-bottom: 5px solid #f2f6ff !important;
}

.listTable tbody tr td {
  padding: 0.1rem 1rem !important;
}

.tableHead thead tr th {
  padding: 0.5rem 1rem 0.5rem !important;
  text-align: center !important;
}

.backButton {
  // font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  color: #475e79;
}

.customButton {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  box-shadow: none !important;
  height: calc(1.5em + 0.75rem) !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
}

.textareaAuto {
  height: auto !important;
}

.splitText {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.errorCard {
  margin-top: 1rem;
  letter-spacing: 0.2rem;
}

.formErrorCard {
  background-color: #fff !important;
  margin-top: 1rem;
  letter-spacing: 0.2rem;
  font-size: 10px;
}

.loaderCard {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  letter-spacing: 0.2rem;
  color: #525965 !important;
  width: 180px !important;
}

.loaderCard7 {
  margin-top: 10rem;
  margin-bottom: 1rem;
  letter-spacing: 0.2rem;
  color: #525965 !important;
  width: 180px !important;
}

.NoItemCard {
  margin-top: 10rem;
  margin-bottom: 1rem;
  letter-spacing: 0.2rem;
  color: #525965 !important;
  width: 250px !important;
}

.slick-next:before,
.slick-prev:before {
  color: #17a2b8 !important;
}

.margin0 {
  margin-left: 0px !important;
  float: right;
}

.four-carousel {
  z-index: 1000 !important;
}

.four-carousel .slick-arrow {
  z-index: 1000 !important;
}

.zindex {
  z-index: -1 !important;
}

.bordertransparent {
  border: 15px solid #f0f0f0 !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.formikError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.oneLineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content.col-12 {
  background-color: #f5f7f9;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000 !important;
  border-color: #000;
}

.pagination-custom {
  width: fit-content;
  margin: auto;
  margin-top: 0rem;
}

.pagination-custom .pagination {
  margin: 0em;
  padding: 1em;
}

.pagination-custom ul {
  display: flex !important;
  appearance: none !important;
  list-style-type: none !important;
}

.pagination-custom ul .active a {
  color: white;
  background-color: #000;
}

.pagination-custom ul .disabled a {
  background-color: #dee2e6 !important;
}

.pagination-custom ul li {
  margin: 0.4em;
  padding: 0em !important;
}

.pagination-custom ul a {
  border-radius: 0.25rem !important;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid #000 !important;
  color: black;
}

.pagination-custom ul a:visited {
  color: #007bff;
}

.notification {
  position: fixed;
  right: 0;
  bottom: 113px;
  left: calc(100% - 376px);
  z-index: 1030;
}

.crossIcon {
  width: 2rem;
  height: 2rem;
  color: #dc3545;
}

.explore-network-wrap {
  background-color: #eadeb8;
  padding: 1rem;
  overflow-y: scroll;
  height: 80vh;
}

.explore-network-wrap p {
  font-weight: 700;
}

.vendorAccordianStyle .MuiAccordionSummary-root {
  height: 30px !important;
  min-height: 30px !important;
}

.image-holder h1 {
  // font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 35px;
  color: #000;
}

.image-holder span {
  // font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #989898;
}

.image-holder span i {
  color: #f2b74e;
  margin-right: 0.3rem;
}

.image-holder .image-warpper {
  background-color: #fffcf2;
}

.image-holder .image-warpper .cover-image {
  height: 12rem !important;
}

.image-holder .image-warpper .multiple-images {
  margin-top: 0.5rem !important;
  height: 12rem !important;
}

.book-now-form .booknow {
  // font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #424453;
}

.warehouse-about-home span {
  // font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #424453;
}

.warehouse-about-home p {
  // font-family: Mulish;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: #828282;
}

.feature-and-service span {
  // font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #424453;
}

.feature-and-service .view-more-feature {
  // font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000;
}

.home-text-heading {
  // font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 35px;
  color: #424453;
}

.homeIconsSize {
  height: 25px !important;
  width: 31px !important;
  padding: 3px;
  object-fit: scale-down;
}

.homeIconsSize.bg-white {
  background-color: white;
}

.homeIconsSize.bg-light-gray {
  background-color: #f3f3f3;
}

.sb-avatar__text {
  width: 43px !important;
  height: 43px !important;
  background: black !important;
}

.featured-card {
  background: #fff;
  border: 0.75px solid #000;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.5rem;
}

.featured-heading {
  // font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
}

.featured-heading ul {
  // font-family: Mulish;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  color: #424453;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #bfbdbd !important;
  // font-family: 'Roboto', sans-serif;
  font-weight: lighter !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbdbd !important;
  // font-family: 'Roboto', sans-serif;
  font-weight: lighter !important;
}

::placeholder {
  color: #bfbdbd !important;
  // font-family: 'Roboto', sans-serif;
  font-weight: lighter !important;
}

.MUIDataTable-responsiveScrollMaxHeight-7 {
  z-index: 99;
}

.custom-select {
  color: #9aa3ac !important;
}

.customer-layout-top {
  margin-top: 5.5rem;
}

.table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}

.fixed-top-height {
  height: 40px;
}

.table-cell table.table tbody tr th,
.table-cell table.table tbody tr td {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

select.form-control[multiple],
select.form-control[size] {
  height: 72px !important;
}

.form-group-p {
  margin-bottom: 5px !important;
}

.css-1ygcj2i-MuiTableCell-root {
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 700;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  background-color: #f2b74e !important;
}

.css-1ex1afd-MuiTableCell-root {
  white-space: nowrap;
  text-transform: capitalize;
  padding-top: 2px;
  padding-bottom: 2px;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 0.2rem 0.8rem !important;
}

.css-nfmglb-MuiTableCell-root {
  padding: 0px !important;
}

.sidebar-background-color {
  background-color: #fff;
  box-shadow: 15px 0px 15px -10px rgba(0, 0, 0, 0.15);
}

.largHeading {
  text-transform: capitalize;
  font-size: 2rem;
  margin-bottom: 1rem !important;
}

.card.custom-card2 {
  overflow: hidden !important;
}

.card.custom-card2:hover,
.card.custom-card2:active,
.card.custom-card2:focus {
  background: #f2b74e !important;
  transform: scale(1.1);
}

.card.custom-card2:hover .onHoverTest,
.card.custom-card2:active .onHoverTest,
.card.custom-card2:focus .onHoverTest {
  color: #fff !important;
  text-decoration: none !important;
  text-transform: capitalize;
}

.onHoverTest {
  color: #000 !important;
  text-decoration: none !important;
  text-transform: capitalize;
}

.footer-heading {
  font-weight: 800;
  letter-spacing: 0.09rem;
  font-size: 16px;
}

footer .nav-link:hover {
  color: #5887c0 !important;
}

.cardHover {
  background: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
}

.cardHover:hover {
  border: 1px solid #f2b74e;
}

.four-element-carousel .card {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
}

.four-element-carousel .card:hover {
  border: 2px solid #f2b74e;
}

.sectionWidth {
  width: 95% !important;
  margin: auto !important;
}

.text-truncate2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.custom-table-tablecontainer table tbody tr:nth-child(1)>th:nth-child(1) {
  background-color: white !important;
  color: black !important;
}

.custom-table-tablecontainer table tbody tr:nth-child(1)>th:nth-child(2) {
  background-color: white !important;
  color: black !important;
}

.custom-table-tablecontainer th {
  background-color: black !important;
  color: white !important;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-table-tablecontainer th:hover {
  color: white !important;
}

.css-dx096b.Mui-active {
  color: white !important;
}

.css-1y3yv5w-MuiTableCell-root {
  background-color: balck !important;
  color: white !important;
}

.whyWarehouse {
  // font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: #fff;
}

.cardActive {
  background: #fff;
  border: 1px solid #f2b74e;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px;
}

.staticBanner {
  background: url('/assets/images/banner-static.jpg');
  height: 343px;
  width: 100%;
}

.staticButton {
  margin-top: 15rem !important;
}

@media screen and (min-width: 991px) {
  .filterMediaQuery {
    display: none !important;
  }
}

.dropbtnHover {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
}

.dropdownHover {
  position: relative;
  display: inline-block;
}

.dropdown-contentHover {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-bottom: 0.5rem solid #f2b74e !important;
  z-index: 1;
}

.dropdown-contentHover .dropdown-item:hover {
  background-color: #fff;
}

.dropdown-contentHover a {
  color: black;
  padding: 4px 6px;
  text-decoration: none;
  display: block;
}

.card.bordertransparent:after {
  content: '';
  width: 109px;
  height: 110px;
  background: #f2b74e;
  position: absolute;
  bottom: -16px;
  right: 0px;
  border-top-left-radius: 96px;
  z-index: 0;
  opacity: 0.5;
}

.dropdown-contentHover a:hover {
  color: #f2b74e;
}

.dropdownHover:hover .dropdown-contentHover {
  display: block;
}

.dropdownHover:hover .dropbtnHover {
  background-color: transparent;
}

.actionIconView {
  font-size: 1.25rem;
  margin: 3px;
  cursor: pointer;
  color: #4f4f4f;
}

.actionIconView:hover {
  color: #868686;
}

.actionIconEdit {
  font-size: 1.1rem;
  margin: 3px;
  cursor: pointer;
  color: #1a51db;
}

.actionIconEdit:hover {
  color: #7fa2fb;
}

.actionIconDelete {
  font-size: 1.1rem;
  margin: 3px;
  cursor: pointer;
  color: #4f4f4f;
}

.actionIconDelete:hover {
  color: #e47a85;
}

.fieldAreaHeight {
  height: 7rem !important;
}

.messageFields {
  background-color: #eadeb8 !important;
}

.theader {
  background-color: #000;
}

.theader th {
  color: white !important;
}

.firstRow {
  background-color: #fff;
}

.secondRow {
  background-color: #f8f6f1;
}

.table-dashboard table.table tbody tr td {
  padding: 0.4rem 1rem !important;
  vertical-align: middle;
}

.customLabel {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 10px 0px;
  color: rgba(0, 0, 0, 0.87);
}

.textOnImage {
  position: relative;
  text-align: center;
  align-items: center;
  color: white;
}

.textOnImage .centered {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  opacity: 1;
  width: 100%;
  transition: 0.5s ease;
}

.navbar .dropdown-menu {
  top: 91% !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-34px {
  font-size: 20px;
}

@media only screen and (min-width: 760px) {
  .fs-34px {
    font-size: 34px;
  }
}

.fs-18px {
  font-size: 18px !important;
}

.view-all-link {
  text-align: center;
}

@media only screen and (min-width: 760px) {
  .view-all-link {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.view-all-link a {
  color: black;
  padding: 5px 20px;
  font-size: 15px;
}

@media only screen and (min-width: 760px) {
  .view-all-link a {
    font-weight: bold;
    background-color: black;
    color: white;
  }
}

.view-all-link a:hover {
  color: #525965;
  text-decoration: none;
}

.title-news-box {
  position: absolute;
  top: 0px;
  left: 10%;
}

.title-news-box p {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  font-family: inherit;
}

.title-news-box.info {
  background-color: #007bff;
}

.title-news-box.warning {
  background-color: #fba443;
}

.news .card .card-img-overlay:before {
  content: ' ';
  position: absolute;
  transition: 0.5s ease-in-out;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(55%, rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.95)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.95) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.95) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.95) 100%);
  z-index: 1;
}

.news .card .card-img-overlay .content-box-news {
  z-index: 9;
  position: absolute;
  bottom: 5%;
}

.news .card .card-img-overlay .content-box-news a:hover {
  text-decoration: none;
}

.news .card .card-img-overlay .content-box-news a:hover .card-title {
  color: #5887c0 !important;
}

.news .card .card-img-overlay .content-box-news a .card-title {
  color: white;
  font-size: 20px;
}

.news .card .card-img-overlay .content-box-news a .card-title.font_large {
  font-size: 20px;
}

@media only screen and (min-width: 760) {
  .news .card .card-img-overlay .content-box-news a .card-title.font_large {
    font-size: 36px;
  }
}

.news .card .card-img-overlay .content-box-news .card-text {
  color: white;
}

.news .card .card-img-overlay .content-box-news a {
  color: white;
}

.news .card .card-img-overlay .content-box-news a:hover {
  color: #5887c0;
  text-decoration: none;
}

.news .card .card-img-overlay .content-box-news a .card-text {
  color: white;
}

.news .card:hover .card-img-overlay:before {
  transition: 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.3);
}

.text-truncate2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.sticky-top-custom {
  position: -webkit-sticky;
  position: sticky;
  top: 83px;
  z-index: 1020;
}

.line {
  position: relative;
}

.line::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #525965;
  left: -14px;
  top: -3px;
}

.widget ul li::before {
  background: #525965;
  height: 7px;
  width: 7px;
  content: '';
  left: 0;
  top: 7px;
  position: absolute;
}

.fusion-responsive-typography-calculated {
  font-size: 26px;
  text-align: inherit !important;
}

.line-arrow-center::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 85%;
  width: 2px;
  height: 16px;
  background-color: #e0e0e0;
  transform: translate(-50%, -50%);
}

.most_popular .card .text-decoration-none h5:hover {
  color: #5887c0 !important;
}

.most_popular_slider .slick-arrow {
  position: absolute;
  right: 20px;
  top: -40px;
}

.most_popular_slider .card .card-img-top {
  transition: 0.5s ease-in-out;
}

.most_popular_slider .card:hover .card-img-top {
  transition: 0.5s ease-in-out;
  transform: scale(1.2);
}

.categories .list-group .list-group-item .badge {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

.categories .list-group .list-group-item .badge.warning {
  background-color: #fba443;
}

.categories .list-group .list-group-item .badge.success {
  background-color: green;
}

.categories .list-group .list-group-item .badge.pink {
  background-color: palevioletred;
}

.more-news-slide .bg-after {
  position: relative;
}

.more-news-slide .bg-after::before {
  content: '';
  width: 100%;
  background-color: #f5f5f5;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.more-news-slide .slick-arrow {
  position: absolute;
  right: 20px;
  top: -40px;
}

.highlighted-text .border-left {
  border-left: 4px solid #525965 !important;
}

.news-details-tabs .nav-tabs {
  border-bottom: none;
}

.news-details-tabs .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #eadeb8;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 0px;
}

.news-details-tabs .nav-tabs .nav-link {
  background-color: #e6e5e2;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 0px;
  color: black;
}

.news-details-tabs .nav-tabs .nav-link.active {
  color: white;
  border-color: #dee2e6 #dee2e6 #fff;
  background-color: #f2b74e !important;
  color: white;
  border-radius: 0px;
}

.blog-details-banner .bg-img-banner {
  padding-top: 120px;
  background-position: center;
}

.blog-details-banner .bg-img-banner .overlay-dark-bottom {
  position: relative;
}

.blog-details-banner .bg-img-banner .overlay-dark-bottom::before {
  content: ' ';
  position: absolute;
  transition: 0.5s ease-in-out;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.95) 100%);
}

.blog-details-banner .bg-img-banner .overlay-dark-half {
  position: relative;
}

.blog-details-banner .bg-img-banner .overlay-dark-half::before {
  content: ' ';
  position: absolute;
  transition: 0.5s ease-in-out;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 15%, rgba(0, 0, 0, 0.95) 100%);
}

.contact-us-banner .breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #fff;
  content: '/';
}

.contact-area::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 600px;
  width: 600px;
  content: '';
  background: url('../img/contact_after-img.png');
  z-index: -1;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
}

.filter-section::after {
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%) !important;
}

.filter-section .india-map .btn-map .state-name {
  padding: 0.2rem 0.8rem;
  font-size: 70%;
  color: black !important;
  background: white !important;
  border-radius: 8px;
}

.testimonials .slick-arrow {
  position: absolute;
  right: 20px;
  top: -60px;
}

.menu-update {
  position: fixed !important;
  width: 300px;
  top: 81px !important;
  bottom: 0px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.menu-update ::-webkit-scrollbar {
  width: 10px !important;
}

.menu-update ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.menu-update ::-webkit-scrollbar-thumb {
  background: lightgray !important;
}

.menu-update ::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.menu-update .terms-and-condition-content .content {
  padding-right: 2rem;
}

.menu-update.sidebar-dashboard {
  background: #000 !important;
  border-radius: 0px;
  overflow-y: inherit !important;
}

@media only screen and (min-width: 760) {
  .menu-update.sidebar-dashboard {
    position: unset;
  }
}

.menu-update.sidebar-dashboard .menus {
  border-radius: 0px;
  margin-top: -7px;
  height: 100% !important;
  overflow-y: inherit !important;
  padding-bottom: 20px;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link {
  font-size: 1rem !important;
  padding: 0.625rem 1.2rem 0.625rem 3rem !important;
  color: white;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link i {
  color: white;
  padding-right: 14px;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link::after {
  background-color: #2e3131 !important;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.active {
  background-color: #f2b74e;
  color: white;
  border-radius: 0px;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.active i {
  color: white;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.active:before {
  background-color: transparent !important;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link:hover {
  background-color: #f2b74e;
  color: white;
  border-radius: 0px;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#sub-users .nav-link,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#documents .nav-link,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#services .nav-link,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#rfq .nav-link,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#my-waresheet .nav-link {
  padding: 5px 18px 0px 94px !important;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#sub-users .nav-link.active,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#documents .nav-link.active,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#services .nav-link.active,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#rfq .nav-link.active,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#my-waresheet .nav-link.active {
  color: #79b9e2;
  background: transparent;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#sub-users .nav-link.active:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#documents .nav-link.active:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#services .nav-link.active:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#rfq .nav-link.active:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#my-waresheet .nav-link.active:before {
  background: #79b9e2 !important;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#sub-users .nav-link:hover,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#documents .nav-link:hover,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#services .nav-link:hover,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#rfq .nav-link:hover,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#my-waresheet .nav-link:hover {
  color: #79b9e2;
  background: transparent;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#sub-users .nav-link:hover:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#documents .nav-link:hover:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#services .nav-link:hover:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#rfq .nav-link:hover:before,
.menu-update.sidebar-dashboard .menus .nav-pills .nav-link.nav-drop+#my-waresheet .nav-link:hover:before {
  background: #79b9e2 !important;
}

.menu-update.sidebar-dashboard .menus .nav-pills #sub-users .nav-link:before,
.menu-update.sidebar-dashboard .menus .nav-pills #documents .nav-link:before,
.menu-update.sidebar-dashboard .menus .nav-pills #services .nav-link:before,
.menu-update.sidebar-dashboard .menus .nav-pills #rfq .nav-link:before,
.menu-update.sidebar-dashboard .menus .nav-pills #my-waresheet .nav-link:before {
  content: ' ';
  top: 50%;
  width: 7px;
  height: 7px;
  left: 78px;
  background: white;
  border-radius: 50%;
  transform: translateY(-50%);
}

.menu-update.sidebar-dashboard .menus .nav-drop.active {
  background-color: transparent;
  color: white;
}

.menu-update .nav-drop i::after {
  color: white !important;
}

.new-card-box .dashboard-card {
  border-radius: 12px;
  border: none !important;
}

.new-card-box .dashboard-card .icons-box {
  background-color: #efefef;
  height: 62px;
  min-width: 62px;
  width: 62px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-card-box .dashboard-card .icons-box img {
  height: 100% !important;
}

.new-card-box .dashboard-card:hover {
  background-color: #f2b74e !important;
}

.new-card-box .dashboard-card:hover .icons-box {
  background-color: white;
}

.new-card-box .dashboard-card:hover h6,
.new-card-box .dashboard-card:hover p {
  color: white !important;
}

.new-card-box a {
  text-decoration: none !important;
}

.css-11mde6h-MuiPaper-root {
  box-shadow: inherit !important;
  border: 1px solid #dee2e6;
}

.css-11mde6h-MuiPaper-root .css-1ygcj2i-MuiTableCell-root {
  background-color: #000 !important;
  color: white !important;
}

.What-We-Do .box-what-we-do {
  width: 120px;
  height: 120px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.What-We-Do .box-what-we-do i {
  color: white;
  transform: scale(4);
}

.What-We-Do .box-what-we-do p {
  color: #000 !important;
}

.background-title-events {
  font-weight: 900;
  font-size: 92px;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  word-break: break-all;
  -webkit-text-stroke: 1px #f8f1f3;
  position: absolute;
  top: -36px;
  left: 0%;
}

@media only screen and (min-width: 760) {
  .background-title-events {
    display: none;
  }
}

.event-card .event-date {
  top: 15px;
  left: 20px;
  z-index: 1;
  font-weight: 700;
  position: absolute;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: -1px 1px 3px #000;
}

.popular-influencers-carousel .card {
  border: 0;
  border-radius: 10px;
}

.popular-influencers-carousel .card,
.popular-influencers-carousel .card .text-gray-600 {
  transition: all 0.5s;
}

.popular-influencers-carousel .slick-slide:nth-child(4n + 1) .card {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.popular-influencers-carousel .slick-slide:nth-child(4n + 2) .card {
  margin-top: 6rem;
  margin-bottom: 0rem;
}

.popular-influencers-carousel .slick-slide:nth-child(4n + 3) .card {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.popular-influencers-carousel .slick-slide:nth-child(4n + 4) .card {
  margin-top: 0rem;
  margin-bottom: 6rem;
}

.table-custom-th-none thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root th span {
  color: white !important;
}

.MuiTableHead-root.css-15wwp11-MuiTableHead-root {
  background-color: #000;
  color: white !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall.css-1m13d3u-MuiTableCell-root {
  padding-left: 12px !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-paddingNone.MuiTableCell-sizeSmall.text-nowrap.css-1y3yv5w-MuiTableCell-root {
  padding-left: 12px !important;
  white-space: nowrap;
}

.table .secondRow {
  background-color: white !important;
}

.sidebar-menu.custom-menu-vendor {
  background-color: #000;
}

.sidemenu-wrapper.toggled .sidebar-wrapper {
  margin-top: -3px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  background-color: #000;
}

.sidebar-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.sidebar-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.sidebar-theme .sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-theme .sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-theme .sidebar-wrapper .sidebar-brand>a,
.sidebar-theme .sidebar-wrapper .sidebar-menu ul li a,
.sidebar-theme .sidebar-footer>a {
  opacity: 1 !important;
  color: white !important;
  font-size: 15px !important;
}

.sidebar-theme .sidebar-wrapper .sidebar-menu ul li a i,
.sidebar-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.sidebar-theme .sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-theme .sidebar-wrapper .sidebar-search .input-group-text {
  background-color: #000 !important;
  font-size: 15px !important;
}

.fa-circle:before {
  background: #000 !important;
}

.vendorAccordianStyle .MuiAccordionSummary-root {
  height: 45px !important;
}

header .navbar .nav-btns .notification-badge .badge {
  background-color: #000 !important;
}

.size-user-icons.sb-avatar__text {
  background-color: #000 !important;
}

.overlay.deep-primary-overlay:after {
  background-color: #f2b74e !important;
}

.text-truncate2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  padding-right: 2px;
}

.custom-table-dark .css-1bigob2 {
  padding: 0.1rem 1rem !important;
  vertical-align: middle;
  background-color: #000 !important;
  color: white !important;
}

.custom-table-dark .css-q34dxg {
  padding: 0.1rem 1rem !important;
  vertical-align: middle;
}

.custom-table-dark .css-lp9mh7 {
  padding-top: 0px;
}

.table.table-dark-custom th {
  padding: 0.4rem 1rem !important;
  vertical-align: middle;
  background-color: #000 !important;
  color: white !important;
}

.white-box-icon {
  width: 41px;
  padding-top: 5px;
  background: white;
  text-align: center;
  justify-content: center;
  display: flex;
  height: 28px;
}

.menu-update.sidebar-dashboard .menus .nav-pills .nav-link {
  font-size: 15px !important;
}

.active.align-items-center.btn {
  position: relative;
}

.active.align-items-center.btn::before {
  content: ' ';
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

@media screen and (min-width: 768px) {
  .navbar-toggler.border-0 {
    display: none;
  }
}

.bg-gradient-glossary {
  border-radius: 10px;
  background: #2f95b1;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2f95b1, #302371);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2f95b1, #302371);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.input-height-41 {
  height: 41px !important;
}

.glossary-box .text-title-box {
  width: 40px;
  height: 40px;
  background: rgba(84, 125, 191, 0.1);
  border-radius: 5px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: #f2b74e;
}

.glossary-box a {
  text-decoration: none;
}

.glossary-box a:hover {
  text-decoration: none !important;
}

.glossary-box li {
  list-style: none;
  color: black;
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  padding-bottom: 8px;
}

.custom-card:hover {
  border: 0 !important;
}