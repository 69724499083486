


#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
}

#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
}



#sidebarCollapse span:first-of-type {
    /* rotate first one */
    transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
    /* second one is not visible */
    opacity: 0;
}
#sidebarCollapse span:last-of-type {
    /* rotate third one */
    transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
    /* no rotation */
    transform: none;
    /* all bars are visible */
    opacity: 1;
    margin: 5px auto;
}

.wrapper {
    display: flex;
    align-items: stretch;
    perspective: 1500px; 
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #7386D5;
    color: #fff;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: center left; /* Set the transformed position of sidebar to center left side. */
}

#sidebar.active {
    margin-left: -250px;
    transform: rotateY(100deg); /* Rotate sidebar vertically by 100 degrees. */
}

@media (max-width: 768px) {
    /* Reversing the behavior of the sidebar: 
       it'll be rotated vertically and off canvas by default, 
       collapsing in on toggle button click with removal of 
       the vertical rotation.   */
    #sidebar {
        margin-left: -250px;
        transform: rotateY(100deg);
    }
    #sidebar.active {
        margin-left: 0;
        transform: none;
    }

    /* Reversing the behavior of the bars: 
       Removing the rotation from the first,
       last bars and reappear the second bar on default state, 
       and giving them a vertical margin */
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
        transform: none;
        opacity: 1;
        margin: 5px auto;
    }

    /* Removing the vertical margin and make the first and last bars rotate again when the sidebar is open, hiding the second bar */
    #sidebarCollapse.active span {
        margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
        transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
        transform: rotate(-45deg) translate(1px, -1px);
    }
}

@media (min-width:1020px) {
    .fontsize{
        font-size: 4.5rem;
        font-weight: 600;
        line-height: 1.2;
    }
}
@media (max-width:576px){
    .fontsize{
        font-size: 3.5rem;
        font-weight: 400;
        line-height: 1.2;
    }
}
.btn-lighting-shadow {
    background-color: orange; /* Button color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  @media (min-width:1024px) {
    .dialog{
      max-width: 25%;
      width: 100%; /* Ensures the modal takes up the full width allowed by max-width */
      position: absolute;
      top: 50%; /* Moves the modal down 50% from the top */
      left: 50%; /* Moves the modal right 50% from the left */
      transform: translate(-50%, -50%); /* Centers the modal by moving it back 50% of its own width and height */
    }
  }

  @media (min-width:768px) and (max-width:1024px) {
    .dialog{
      max-width: 50%;
      width: 100%; /* Ensures the modal takes up the full width allowed by max-width */
      position: absolute;
      top: 50%; /* Moves the modal down 50% from the top */
      left: 50%; /* Moves the modal right 50% from the left */
      transform: translate(-50%, -50%); /* Centers the modal by moving it back 50% of its own width and height */
    }
  }

  @media (max-width:576px) {
    .dialog{
        max-width: 90%;
        width: 100%; 
        position: absolute;
        top: 50%; /* Moves the modal down 50% from the top */
        left: 50%; /* Moves the modal right 50% from the left */
        transform: translate(-50%, -50%); /* Centers the modal by moving it back 50% of its own width and height */
    }
  }

  .react-autocomplete-input input {
    font-weight: 400 !important; /* or use another selector found in your inspection */
  }

  .sc-htpNat.ViWoI.clear-icon {
    /* Custom styles for the div containing the icon */
    padding: 0px; /* Example: Add some padding around the icon */
    margin:0px;
  }
  .sc-ifAKCX.ccnHqI ul {
    list-style-type: none; /* Remove bullet points */
    padding-left: 0; /* Remove any left padding */
  }
  .sc-bxivhb.cLxgFs input{
    list-style-type: none;
  }
  
  .sc-htpNat.ViWoI.clear-icon svg {
    /* Custom styles for the SVG icon */
    width: 24px; /* Example: Increase the size of the icon */
    height: 24px;
  }
  .sc-bxivhb.eqpTLV input {
    font-weight: normal; /* Change this to 'normal', 'bold', 'lighter', or any numeric value like 500 */
  }

  .sc-bxivhb.cLxgFs{
    padding: 0;
  }

  .sc-bxivhb.cLxgFs input{
    font-weight: normal;
  }
  
  .bottom{
    position: relative;
    bottom: 3px;
  }

  .borderradius{
    border-radius: 0.5rem;
  }

  
  
