 @media(min-width:576px){
    .forgot-lg{
      margin-left: 135px;
    }
    .backtologin{
      margin-top: 15px;
      display: flex;
      gap: 11.5rem;

    }
    .border-style{
      border-radius: 10px;
    }
    .form-control{
      border-radius: 0;
    }
  }

 @media(max-width:576px){
    .forgot-lg{
      margin-left: 75px;
    }
    .backtologin{
      margin-top: 15px;
      display: flex;
      gap:0px;
    }
    .signup{
         width: 100%;
    }
    .signin{
      width: 40%;
    }
    .custom-lg{
      margin-top:0px;
    }
  }

  

  